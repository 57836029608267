* {
    color: #fff;
    transition: background-color 0.5s ease;
}

html {
    background-color: #102027;
}

.App {
    margin: auto;
    max-width: 800px;
    padding-bottom: 50px;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

code,
pre {
    background-color: #282A36;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
}

h1 {
    font-size: 280%;
}

h2 {
    margin-top: 60px;
    margin-bottom: 10px;
}

.block {
    display: block;
}

a {
    color: rgb(187, 134, 252);
}

.chip {
    border: solid 2px rgb(187, 134, 252);
    background: none;
    cursor: pointer;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 100px;
    color: rgb(187, 134, 252);
    font-weight: 500;
}

.chip:hover {
    background: rgba(187, 134, 252, 0.15);
}

.chip--selected {
    background: rgb(187, 134, 252);
    color: #102027;
}

.chip--selected:hover {
    background: rgb(187, 134, 252);
}

li {
    margin: 4px 0;
}

::selection {
    color: #102027;
    background: #fff;
}
  
::-moz-selection {
    color: #102027;
    background: #fff;
}
